<template>
	
  <div style="background-color:#f5f5f5 ">
	  <div class="roll">
	        <div class="roll-line" />
	        <i class="el-icon-bell"></i>
	        <ul class="list">
	          <li
	            v-for="(item, index) in ulList"
	            :key="item.id"
	            :class="!index && play ? 'toUp' : ''"
	          >
	           {{ item.getTime }} ：{{ item.content }}
	          </li>
	        </ul>
	  </div>
    <!-- -轮播图-->
    <el-carousel height="450px">
<!--      <el-carousel-item v-for="(item,index) in lunbos" :key="index">-->
<!--        <el-image :src="item.src" fit="fill" style="width: 100%;height: 100%"></el-image>-->
<!--      </el-carousel-item>-->
            <el-carousel-item>
              <el-image :src="url" fit="fill" style="width: 100%;height: 100%"></el-image>
            </el-carousel-item>
    </el-carousel>
    </div>
</template>

<script>
  import imgUrl from '@/images/lunbo1.png'
  import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name:'fronthome',
    props: [],
    components: {
      vueSeamlessScroll,
    },
    data() {
      return {
        reverse:true,
        animate:false,
        lunbos:[{src:""}],
		url:imgUrl,
		ulList: [
		],
		play: false,
		timer: null, // //接收定时器
		user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
      }
    },
    watch: {},
    computed: {
      classOption () {
        return {
          step: 0.2, // 数值越大速度滚动越快
          limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 1, // 0向下 1向上 2向左 3向右
          openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
        }
      },
    },
    beforeCreate() {
      // this.request.get('/sysdata/findBy?name='+"lunbo").then(res=>{
      //   this.lunbos=res.data;
      // });
    },
    created() {
		 type:this.user.gongyingshangtype+"中标公告",
			this.request.get("/notice/findBy?type="+this.user.gongyingshangtype+'中标公告', {
			  }).then(res => {
				this.ulList = res.data
			  })
    },
    beforeMount() {},
    mounted() {
		 setInterval(this.startPlay, 4000);
		     console.log('开始定时器');
	},
    beforeUpdate() {},
    updated() {},
    destroyed() {
		clearInterval(this.timer); 
		    console.log('销毁定时器');
	},
    methods: {
      handleSelect() {},
	   startPlay() {
	        let that = this;
	        that.play = true; //开始播放
	        that.timer = setTimeout(() => {  //创建并执行定时器
				  that.ulList.push(that.ulList[0]); //将第一条数据塞到最后一个
				  that.ulList.shift(); //删除第一条数据
	          that.play = false; //暂停播放
	        }, 500);
	        console.log(that.timer);
	      },
    },
    filter: {},
  }
</script>

<style scoped>
  .seamless-warp {
    width: 100%;
    height: calc(100% - 360px);
    overflow: hidden;
  }
  .roll {
    width: 100%;
    height: 60px; /*关键样式*/
    line-height: 60px; /*关键样式*/
    background: #fff;
    margin-bottom: 24px;
    box-shadow: 4px 0 10px rgba(226, 226, 226, 0.3);
    padding: 0 20px;
    color: #f5212d;
    font-size: 14px;
    display: flex;
	}
    .roll-line {
      width: 2px;
      height: 100%;
      background: #dee2e6;
      margin: 0 20px 0 -20px;
    }
    .el-icon-bell {
      color: #343a40;
      line-height: 60px; /*关键样式*/
      margin-right: 10px;
    }
    .toUp {
      margin-top: -60px; /*关键样式*/
      transition: all 1s; /*关键样式*/
    }
    .list {
      list-style: none;
      width: 100%;
      text-align: center;
      overflow: hidden; /*关键样式*/
      height: 60px; /*关键样式*/
      padding: 0;
      margin: 0;
    }
    li {
      text-align: left;
      height: 60px; /*关键样式*/
      line-height: 60px; /*关键样式*/
    }
</style>